import React, { useEffect } from 'react';
import PolicyTemplate from '../components/PolicyTemplate';

function ShippingPolicy() {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <PolicyTemplate
         metaTitle="🥇 Generar la Política de Envío para Shopify Gratis en Español 2023"
         metaDescription="Genera la política de envío para tu tienda de Shopify accediendo ahora a este generador."
         title="Genera la Política de Envío para tu tienda Shopify"
         description="A partir del formulario que tienes a la derecha, podrás generar una plantilla de envío para tu tienda de Shopify."
         textareaTitle="Política de Envío"
         textareaDescription="Se denominan como políticas de envío al conjunto de normas que describen cómo se llevará a cabo el proceso de envío de los productos adquiridos. Las políticas de envío son importantes porque aportan confianza al comprador (le aportan información concreta sobre cómo se gestionará el envío y la entrega de su pedido). Permiten que el cliente sepa cuándo y en qué condiciones va a recibir su pedido."
         textareaType={3}
      />
   );
}

export default ShippingPolicy;
