import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

function ResponsabilityModal() {
   const [show, setShow] = useState(false);

   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   return (
      <>
         <span className="text-primary" onClick={handleShow} style={{ cursor: 'pointer' }}>
            esta exención de responsabilidad.
         </span>
         <div>
            <Modal show={show} onHide={handleClose}>
               <Modal.Header closeButton>
                  <Modal.Title>Advertencia Legal</Modal.Title>
               </Modal.Header>
               <Modal.Body className="text-justify p-4">
                  <p className="">
                     Estos ejemplos de plantillas no son aviso legal y mediante su uso estás aceptando esta advertencia. El siguiente material es sólo para propósito informativo y
                     no constituye publicidad, licitación o aviso legal.
                  </p>
                  <p>
                     Deberías buscar consejo legal independiente antes de publicar estos acuerdos. Lee la información generada con cuidado y modifica, borra o agrega todas las
                     áreas que sean necesarias.
                  </p>
                  <p>
                     El uso, acceso o transmisión de estos materiales, información o cualquiera de los enlaces contenidos en estos documentos no pretende crear, y la recepción de
                     la misma no constituye la formación de una relación de cliente-abogado entre generadordepoliticas.com y el usuario del navegador.
                  </p>
                  <p>
                     No deberías confiar en esta información para cualquier propósito sin buscar asesoramiento legal de un abogado con licencia en tu estado o provincia. La
                     información contenida se proporciona únicamente como información general y puede o no reflejar los desarrollos legales más actuales; en consecuencia, la
                     información no promete o garantiza que sea correcta o completa. generadordepoliticas.com renuncia expresamente a toda responsabilidad con respecto a cualquier
                     acción tomada o no tomada en base a cualquiera o todos los contenidos de este sitio web.{' '}
                  </p>
                  <p>
                     Además, generadordepoliticas.com no necesariamente respalda ni es responsable de ningún contenido de terceros al que se pueda acceder a través de esta
                     información.
                  </p>
               </Modal.Body>
               <Modal.Footer>
                  <button className="button--primary btn btn-block" onClick={handleClose}>
                     De Acuerdo
                  </button>
               </Modal.Footer>
            </Modal>
         </div>
      </>
   );
}

export default ResponsabilityModal;
