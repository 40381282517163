import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Law from '../../assets/img/law.jpg';
import Peter from '../../assets/img/peter-ordenador.png';
import Metatags from '../../components/Metatags';

function LosTextosLegalesParaShopify() {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <main className="container" style={{ maxWidth: '620px' }}>
         <Metatags
            title="Las 4 Políticas Legalmente Obligatorias que ha de Tener tu Tienda Shopify"
            description="Las políticas de tu tienda Shopify son necesarias para cumplir con la ley. Descubre cuáles son y lo que han de contener cada una de ellas"
            image={Law}
         />
         <div className="mt-5">
            <h1 className="h3 mb-4">
               <b>Las 4 Políticas Legalmente Obligatorias que ha de Tener tu Tienda Shopify en 2023</b>
            </h1>
         </div>
         <hr />
         <div className="row my-4">
            <div className="col-6">
               <div className="row">
                  <div className="col-auto">
                     <img src={Peter} className="img-fluid rounded-circle" width={50} />
                  </div>
                  <div className="col-auto">
                     <div className="h6 font-weight-bolder text-primary">Peter Palmer</div>
                     <div className="text-muted">16 de agosto, 2021</div>
                  </div>
               </div>
            </div>
            <div className="col-6"></div>
         </div>
         <hr />
         <div className="content text-muted text-justify mb-5" style={{ fontSize: '18px' }}>
            <p>
               Los conocidos avisos legales, que van desde la politica de envio para Shopify hasta el uso de las cookies, son partes esenciales e ineludibles del contenido general
               de una web, sobre todo si tu objetivo es el de realizar negocios.
            </p>
            <p>
               Esto tiene su razón de ser por la obligación que existe de dar a conocer, y exponer al público, las normas de carácter mercantil por las que se va a regir tu web.
               Deben estar recogidos en los llamados textos legales de forma clara y motivada, para lo cual has de consultar previamente con un especialista y luego redactarlos
               haciendo uso de nuestro generador de políticas.
            </p>
            <img src={Law} className="img-fluid mb-3 round" alt="Textos Legales Shopify 2023" />
            <p>
               Los textos legales son los documentos que dan fe de que tu actividad cumple con todos los requisitos para su funcionamiento, los cuales debes concretar como te
               comentamos a continuación.
            </p>
            <h4 className="text-dark font-weight-bold">Términos y Condiciones del Servicio</h4>
            <p>
               Determinan las normas obligatorias a cumplir para que el establecimiento de una relación comercial entre tú, como vendedor, y tu cliente sea factible y legal. Es un
               contrato de adhesión que convienes tú y cuyas cláusulas no son objeto de negociación. O el cliente las acepta o no se puede llevar a cabo ninguna transacción
               mercantil.
            </p>
            <p>
               La aceptación, incluida la de las cookies, debe ser solicitada y dada expresamente, por ello es importante que recalques en la redacción la forma en la que se hará
               llegado el momento.
            </p>
            <h4 className="text-dark font-weight-bold">Política de Privacidad</h4>
            <p>
               La politica de privacidad de Shopify es el espacio donde debes informar al usuario de qué datos recopilas sobre él mientras navega, usa un formulario o realiza algún
               pedido y, también, el porqué y para qué vas a hacer uso de ellos.
            </p>
            <p>En definitiva, ha de quedar claro:</p>
            <ul>
               <li>Qué tipo de datos vas a tomar, dónde van a quedar almacenados, qué motivo tienes para hacerlo y durante cuánto tiempo los guardarás.</li>
               <li>
                  La identidad del propietario de la web, ya seas tú como persona física o una sociedad, y la de la persona legitimada para recopilarlos, salvaguardarlos y hacer
                  uso de ellos.
               </li>
               <li>En el caso de que vayas a compartirlos con terceros, tienes que dar a conocer con quién, de qué manera y para qué.</li>
               <li>
                  Siempre debes informar al usuario de todos los derechos que le asisten respecto a la apelación, eliminación y rectificación de la información obtenida sobre él.
               </li>
            </ul>
            <p>
               Te recomendamos asesorarte y usar nuestro <Link to="/politica-de-privacidad">Generador de Políticas para Shopify</Link> para crear la política de privacidad Shopify.
               De esta forma, ahorrarás tiempo y esfuerzo.
            </p>
            <h4 className="text-dark font-weight-bold">Políticas de Envío y Reembolso</h4>
            <p>
               En un e-commerce, algo que cae por su propio peso es que estas políticas las debes tener bien atadas y redactadas, ya que forman parte del eje central de cualquier
               operación comercial que se realice por internet.
            </p>
            <ul>
               <li>
                  La política de envío es la que marca las condiciones generales y particulares, la manera de recepción de los productos (o prestación de servicios), los gastos
                  necesarios y los tiempos máximos y mínimos.
               </li>
               <li>
                  En cuanto a la politica de reembolso de Shopify, debemos contemplar los casos en los que un cliente puede solicitar la devolución de lo pagado, los motivos
                  aceptados y la manera y los tiempos en que se tiene que hacer.
               </li>
            </ul>
            <p>
               Ten siempre constancia, ya sea por medio de albaranes, documentos de seguimiento u otras formas de rastreo, de la realización de estas operaciones, ya que así lo
               marca la ley y, además, son necesarios ante una reclamación.
            </p>
            <p>
               Por último, recuerda que, en la creación a medida de las condiciones, las cookies o la política de envío en Shopify, necesitarás del asesoramiento de un abogado y
               después, según sus instrucciones, ya podrás plasmarlas fácilmente con nuestro generador de politicas para Shopify.
            </p>
            <span class="badge badge-primary mr-2">eCommerce</span>
            <span class="badge badge-primary mr-2">Shopify</span>
            <span class="badge badge-primary mr-2">Dropshipping</span>
            <span class="badge badge-primary mr-2">Políticas</span>
            <span class="badge badge-primary mr-2">Textos Legales</span>
            <span class="badge badge-primary mr-2">Generador de Políticas</span>
            <span class="badge badge-primary mr-2">Legal</span>
         </div>

         <hr />
         <div className="row my-4">
            <div className="col-6">
               <div className="row">
                  <div className="col-auto">
                     <img src={Peter} className="img-fluid rounded-circle" width={50} />
                  </div>
                  <div className="col-auto">
                     <div className="h6 font-weight-bolder text-primary">Peter Palmer</div>
                     <div className="text-muted">16 de agosto, 2021</div>
                  </div>
               </div>
            </div>
            <div className="col-6"></div>
         </div>
         <hr />
      </main>
   );
}

export default LosTextosLegalesParaShopify;
