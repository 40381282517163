import React, { useState } from 'react';
import jump from 'jump.js';
import TrafficAds from '../../components/TrafficAds';
import BusinessForm from '../../components/BusinessForm';
import EmailModal from './EmailModal';
import MailerFindAd from '../../components/MailerFindAd';

function BusinessInformation(props) {
   const [data, setData] = useState(null);
   const [loading, setLoading] = useState(false);
   const [showModal, setShowModal] = useState(false);

   const sendData = (data) => {
      setLoading(true);
      setData(data);
      setShowModal(true);
   };

   const hasSubmitted = () => {
      // Has submitted the form.
      // Disable Loading before scrolling
      setLoading(false);

      // Scroll down to textarea section
      jump('#textareaSection', {
         duration: 1000,
         offset: -80,
         a11y: false,
      });

      props.sendData(data);
   };

   return (
      <>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
               fill="#0062ff "
               fillOpacity="1"
               d="M0,160L60,154.7C120,149,240,139,360,160C480,181,600,235,720,261.3C840,288,960,288,1080,250.7C1200,213,1320,139,1380,101.3L1440,64L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
         </svg>
         <section className="bg-primary" id="businessInformation">
            <div className="container">
               <div className="row">
                  <div className="col-lg-5 px-none">
                     <h2 className="text-white mb-4">
                        <b>
                           ¡Ya solo estás a un paso más!{' '}
                           <span role="img" aria-label="party">
                              🥳
                           </span>
                        </b>
                     </h2>
                     <h3 className="text-white lead mb-3">Rellena la información del formulario para poder generar tus políticas.</h3>
                     <MailerFindAd />
                  </div>
                  <div className="col-lg-7">
                     <BusinessForm sendData={sendData} setLoading={setLoading} loading={loading} />
                  </div>
               </div>
            </div>
         </section>
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 300">
            <path
               fill="#0062ff"
               fillOpacity="1"
               d="M0,224L60,224C120,224,240,224,360,192C480,160,600,96,720,80C840,64,960,96,1080,101.3C1200,107,1320,85,1380,74.7L1440,64L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
            ></path>
         </svg>
         <EmailModal showModal={showModal} setShowModal={setShowModal} hasSubmitted={hasSubmitted} />
      </>
   );
}

export default BusinessInformation;
