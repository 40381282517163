import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import ResponsabilityModal from './ResponsabilityModal';

function BusinessForm(props) {
   const [data, setData] = useState({
      businessName: '',
      website: '',
      email: '',
      address: '',
      apartment: '',
      country: '',
      province: '',
      city: '',
      postalCode: '',
      language: 'spanish',
   });

   const [className, setClassName] = useState({
      businessName: '',
      website: '',
      email: '',
      address: '',
      apartment: '',
      country: '',
      province: '',
      city: '',
      postalCode: '',
   });

   useEffect(() => {
      const formData = localStorage.getItem('formData');
      if (!formData) return;
      const data = JSON.parse(formData);
      setData({ ...data, ...data });
      setFormValid();
   }, []);

   // Country selectable
   const selectCountry = (val) => {
      setData({ ...data, country: val });
      setClassName({ ...className, country: 'is-valid' });
   };

   // Region selectable
   const selectRegion = (val) => {
      setData({ ...data, province: val });
      setClassName({ ...className, province: 'is-valid' });
   };

   const setLocationData = (d) => {
      setData({
         ...data,
         country: d.data.country_name,
         city: d.data.city,
         province: d.data.city,
      });
   };

   const setClassNameData = () => {
      setClassName({
         ...className,
         province: 'is-valid',
         city: 'is-valid',
         country: 'is-valid',
      });
   };

   useEffect(() => {
      // Get the user geolocation using IP API
      const getUserLocation = async () => {
         const result = await Axios.get('https://ipapi.co/json/');
         setLocationData(result);
         setClassNameData();
      };

      if (!localStorage.getItem('formData')) getUserLocation();
   }, []);

   // Handle change for normal inputs
   const handleChange = (e) => {
      setData({ ...data, [e.target.name]: e.target.value });
      if (e.target.value.length === 0) {
         setClassName({ ...className, [e.target.name]: 'is-invalid' });
      } else {
         if (e.target.name === 'email') {
            const reEmail = /^(([^<>()\\,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!reEmail.test(e.target.value)) {
               setClassName({ ...className, [e.target.name]: 'is-invalid' });
            } else {
               setClassName({ ...className, [e.target.name]: 'is-valid' });
            }
         } else if (e.target.name === 'website') {
            const reWebsite = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
            if (!reWebsite.test(e.target.value)) {
               setClassName({ ...className, [e.target.name]: 'is-invalid' });
            } else {
               setClassName({ ...className, [e.target.name]: 'is-valid' });
            }
         } else {
            setClassName({ ...className, [e.target.name]: 'is-valid' });
         }
      }
   };

   const formIsValid = () => {
      const values = Object.values(className);

      for (let value of values) {
         //console.log(value);
         if (value === 'is-invalid' || value.length === 0) {
            return false;
         }
      }
      return true;
   };

   const setFormValid = () => {
      setClassName({
         businessName: 'is-valid',
         website: 'is-valid',
         email: 'is-valid',
         address: 'is-valid',
         apartment: 'is-valid',
         country: 'is-valid',
         province: 'is-valid',
         city: 'is-valid',
         postalCode: 'is-valid',
      });
   };

   const highlightEmptyInputs = () => {
      const entries = Object.entries(className);
      for (let [key, value] of entries) {
         if (value.length === 0) {
            setClassName({ ...className, [key]: 'is-invalid' });
         }
      }
   };

   // On Form Submit
   const handleSubmit = (e) => {
      e.preventDefault();

      // Validate data
      if (formIsValid()) {
         props.setLoading && props.setLoading(true);

         // Save data in localstorage
         localStorage.setItem('formData', JSON.stringify(data));

         // Send data to the textareas
         props.sendData(data);
      } else {
         highlightEmptyInputs();
      }
   };

   return (
      <div className="card mx-lg-5 mx-none p-4 rounded mx-auto">
         <div className="card-body">
            <h2 className="mb-4">
               <b>Información de la Empresa</b>
            </h2>
            <form onSubmit={handleSubmit}>
               <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Entidad Legal</label>
                  <input
                     type="text"
                     className={'form-control ' + className.businessName}
                     placeholder="Nombre de la Empresa o Individual"
                     name="businessName"
                     value={data.businessName}
                     onChange={handleChange}
                  />
               </div>
               <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Página Web</label>
                  <input type="text" value={data.website} className={'form-control ' + className.website} placeholder="www.mitienda.com" name="website" onChange={handleChange} />
               </div>
               <div className="form-group">
                  <label htmlFor="exampleFormControlInput1">Email de Contacto</label>
                  <input type="email" value={data.email} className={'form-control ' + className.email} placeholder="contacto@mitienda.com" name="email" onChange={handleChange} />
               </div>
               <div className="row">
                  <div className="col-lg-6">
                     <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Dirección</label>
                        <input
                           type="text"
                           value={data.address}
                           className={'form-control ' + className.address}
                           placeholder="470 Lucky Forks"
                           name="address"
                           onChange={handleChange}
                        />
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Apartamento, suite, etc</label>
                        <input
                           type="text"
                           value={data.apartment}
                           className={'form-control ' + className.apartment}
                           placeholder="YC7B 3UT"
                           name="apartment"
                           onChange={handleChange}
                        />
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-6">
                     <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">País</label>
                        <CountryDropdown value={data.country} onChange={(val) => selectCountry(val)} className={'form-control ' + className.country} name="country" />
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="form-group">
                        <label htmlFor="exampleFormControlSelect1">Provincia, Estado</label>
                        <RegionDropdown
                           country={data.country}
                           value={data.province}
                           onChange={(val) => selectRegion(val)}
                           className={'form-control ' + className.province}
                           name="region"
                        />
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-6">
                     <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Ciudad</label>
                        <input
                           type="text"
                           value={data.city}
                           className={'form-control ' + className.city}
                           placeholder="Barcelona"
                           name="city"
                           onChange={handleChange}
                           value={data.city}
                        />
                     </div>
                  </div>
                  <div className="col-lg-6">
                     <div className="form-group">
                        <label htmlFor="exampleFormControlInput1">Código Postal</label>
                        <input
                           type="text"
                           value={data.postalCode}
                           className={'form-control ' + className.postalCode}
                           placeholder="08022"
                           name="postalCode"
                           onChange={handleChange}
                        />
                     </div>
                  </div>
                  <div className="col-lg-12">
                     <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>Idioma de las Políticas</Form.Label>
                        <Form.Control as="select" value={data.language} name="language" onChange={handleChange}>
                           <option value="spanish">Español</option>
                           <option value="english">Inglés</option>
                        </Form.Control>
                     </Form.Group>
                  </div>
               </div>

               <label className="text-muted mb-3">
                  Al usar estas plantillas, aceptas que has leído y aceptado <ResponsabilityModal />
               </label>
               <button className="button--primary btn btn-block" id="track_me" type="submit" disabled={props.loading}>
                  {props.loading ? (
                     <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                     </div>
                  ) : (
                     '¡Generar las políticas!'
                  )}
               </button>
            </form>
         </div>
      </div>
   );
}

export default BusinessForm;
