import React from 'react';
import PolicyTextarea from '../../components/PolicyTextarea';
import EUCookieBar from '../../assets/img/cookies.png';

function Policies(props) {
   return (
      <section>
         <div className="container my-5" id="textareaSection">
            <PolicyTextarea
               title="Política de Reembolso"
               description="La política de reembolso, o también conocida como política de devolución, informa a tus clientes sobre cómo tu negocio gestiona los reembolsos o devoluciones de los productos que vendes. Una buena política de reembolso o devolución puede proteger a tu empresa y ayudarte a ganar la confianza de tus clientes."
               type={0}
               data={props.data}
            />
            <PolicyTextarea
               title="Política de Privacidad"
               description="La política de privacidad asegura a los clientes que compran a través de tu tienda que los datos personales que están dejando serán recopilados para un fin específico que conocerán y que lo harás siempre bajo su consentiemiento, protegiendo su privacidad."
               type={1}
               data={props.data}
            />
            <PolicyTextarea
               title="Términos del Servicio"
               description="Los términos y condiciones son un conjunto de términos legales definidos por el propietario de una página web. Los Términos son un acuerdo entre el propietario del sitio web y los usuarios de la página web; detallan las políticas y procedimientos realizados por el sitio web. "
               type={2}
               data={props.data}
            />
            <PolicyTextarea
               title="Política de Envío"
               description="Se denominan como políticas de envío al conjunto de normas que describen cómo se llevará a cabo el proceso de envío de los productos adquiridos. Las políticas de envío son importantes porque aportan confianza al comprador (le aportan información concreta sobre cómo se gestionará el envío y la entrega de su pedido). Permiten que el cliente sepa cuándo y en qué condiciones va a recibir su pedido. "
               type={3}
               data={props.data}
            />
            {/*<PolicyTextarea title="Política de Cookies" description="" type={4} data={props.data} /> */}
            {/*<PolicyTextarea title="Aviso Legal" type={4} data={props.data} />*/}
            <div className="card rounded shadow border-0 px-4 py-3 mb-5">
               <div className="card-body">
                  <h2 className="h4">
                     <b>Política de Cookies</b>
                  </h2>
                  <p className="text-muted">
                     La política de cookies es una declaración a sus usuarios sobre qué cookies están activas en tu sitio web, qué datos de usuario rastrean, con qué propósito y a
                     qué parte del mundo se envían estos datos.
                  </p>

                  <div className="row mt-4 border round p-4">
                     <div className="col-auto">
                        <img src={EUCookieBar} width={75} alt="Booster: EU Cookie Bar" className="img-fluid round" />
                     </div>
                     <div className="col">
                        <p className="mb-0">
                           <b>Booster: EU Cookie Bar</b>
                        </p>
                        <p className="text-muted">Te recomendamos que utilices Booster: EU Cookie Bar.</p>
                     </div>
                     <div className="col-auto my-auto">
                        <a
                           className="button--primary btn btn-small float-right align-middle"
                           target="_blank"
                           rel="noreferrer"
                           href="https://apps.shopify.com/eu-cookie-bar?locale=es"
                        >
                           AGREGAR APLICACIÓN
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
}

export default Policies;
