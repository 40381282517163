import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import SimpleNavbar from './components/SimpleNavbar';
import CookiePolicy from './pages/Legal/CookiePolicy';
import { HelmetProvider } from 'react-helmet-async';

// Pages
import Home from './pages/Home';
import Footer from './sections/homeSections/Footer';
import RefundPolicy from './pages/RefundPolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import ShippingPolicy from './pages/ShippingPolicy';
import LosTextosLegalesParaShopify from './pages/blog/LosTextosLegalesParaShopify';

function Router() {
   return (
      <HelmetProvider>
         <BrowserRouter>
            <SimpleNavbar />
            <Switch>
               <Route path="/" exact component={Home} />
               <Route path="/politica-de-reembolso" exact component={RefundPolicy} />
               <Route path="/politica-de-privacidad" exact component={PrivacyPolicy} />
               <Route path="/terminos-del-servicio" exact component={TermsOfService} />
               <Route path="/politica-de-envio" exact component={ShippingPolicy} />
               <Route path="/articulo/las-4-politicas-legalmente-obligatorias-que-ha-de-tener-tu-tienda-shopify" exact component={LosTextosLegalesParaShopify} />
               <Route path="/legal/politica-de-cookies" exact component={CookiePolicy} />
            </Switch>
            <Footer />
         </BrowserRouter>
      </HelmetProvider>
   );
}

export default Router;
