import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

function CookieBanner() {
   return (
      <div>
         <CookieConsent
            location="bottom"
            buttonText="Aceptar"
            cookieName="cookieBannerHasBeenAccepted"
            style={{ background: '#fff', color: '#6c757d', boxShadow: '12px 0px 15px rgba(140, 152, 164, 0.1)' }}
            buttonStyle={{
               color: '#fff',
               boxShadow: '0 2px 8px 0 rgba(0, 34, 255, 0.08), 0 2px 8px 0 rgba(0, 11, 80, 0.12)',
               borderRadius: '99px',
               fontSize: '1rem',
               fontWeight: '700',
               padding: '0.5rem 1rem',
               backgroundImage: 'linear-gradient(90deg, #009bff, #0062ff)',
            }}
            expires={150}
         >
            Utilizamos cookies propias y de terceros para mejorar tu experiencia.{' '}
            <Link className="align-middle" to="/legal/politica-de-cookies" style={{ fontSize: '12px' }}>
               Saber más.
            </Link>
         </CookieConsent>
      </div>
   );
}

export default CookieBanner;
