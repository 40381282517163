import React from 'react';
import presentation from '../../assets/video/terms.mp4';
import jump from 'jump.js';

function Header() {
   const goToFormSection = () => {
      // Scroll down to textarea section
      jump('#businessInformation', {
         duration: 1000,
         offset: -100,
         a11y: false,
      });
   };

   return (
      <section className="pt-5">
         <div className="container mt-5">
            <div className="row">
               <div className="col-lg-6 my-auto">
                  <h1 className="mb-4">
                     <b>
                        Generador de Políticas para tu tienda Shopify{' '}
                        <span role="img" aria-label="rocket">
                           🚀
                        </span>
                     </b>
                  </h1>
                  <h2 className="lead text-muted mb-4">En esta página podrás generar las políticas de privacidad, reembolso, envíos y aviso legal de tu tienda Shopify.</h2>
                  <button className="button--primary btn mr-3" onClick={goToFormSection}>
                     ¡Legalizar mi tienda!
                  </button>
                  <a className="text-primary ml-3" href="/">
                     Saber más {'>'}
                  </a>
               </div>
               <div className="col-lg-6 my-auto mx-auto text-center">
                  <video autoPlay="autoplay" muted="muted" loop="loop" className="img-fluid">
                     <source src={presentation} type="video/mp4" />
                  </video>
               </div>
            </div>
         </div>
      </section>
   );
}

export default Header;
