import React, { useState, useEffect } from 'react';
import Header from '../sections/homeSections/Header';
import BusinessInformation from '../sections/homeSections/BusinessInformation';
import Policies from '../sections/homeSections/Policies';
import CookieBanner from '../components/CookieBanner';
import Reviews from '../components/Reviews';
import Blog from '../sections/homeSections/Blog';

function Home() {
   // Communication between two components
   const [data, setData] = useState({});
   const sendData = (data) => {
      data = setData(data);
   };

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <>
         <Header />
         <BusinessInformation sendData={sendData} />
         <Blog />
         <Reviews
            reviews={[
               { name: 'Andrea Roca', review: 'Mi tienda no me daba la opción de generar las políticas y encontré esta excelente página web!' },
               { name: 'Ignacio Jiménez', review: 'Gracias a esta web pude ahorrarme cientos de euros en abogados.' },
               { name: 'Alba Martínez', review: 'Se agradecen herramientas como estas que realmente te simplifican mucho la vida.' },
            ]}
         />
         <Policies data={data} />
         <CookieBanner />
      </>
   );
}

export default Home;
