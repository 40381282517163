import { makeRequest } from '../api/client';

const verifyEmailExists = async (email) => {
   const options = {
      method: 'GET',
      url: 'https://mailcheck.p.rapidapi.com/',
      params: { domain: email },
      headers: {
         'x-rapidapi-host': 'mailcheck.p.rapidapi.com',
         'x-rapidapi-key': '5ae0d878b3mshf770512930bc83ap17dd05jsn735ae851c516',
      },
   };
   try {
      const response = await makeRequest(options);
      return response.data;
   } catch (error) {
      console.log(error);
   }
};

export default verifyEmailExists;
