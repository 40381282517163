import React from 'react';
import { Link } from 'react-router-dom';

function Blog() {
   return (
      <div className="container mb-5">
         <Link to="/articulo/las-4-politicas-legalmente-obligatorias-que-ha-de-tener-tu-tienda-shopify">
            <div
               className="rounded text-white p-5"
               style={{
                  backgroundColor: '#003994',
                  backgroundImage: 'url(https://htmlstream.com/front/assets/svg/components/abstract-shapes-1.svg)',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'top center',
               }}
            >
               <span class="badge badge-primary">Blog</span>
               <h4 className="mt-3 h3 font-weight-bold">Las 4 Políticas Legalmente Obligatorias que ha de Tener tu Tienda Shopify en 2023</h4>
               <p className="text-decoration-none">
                  Las políticas de tu tienda Shopify son necesarias para cumplir con la obligación que marca la ley. Descubre cuáles son y lo que han de contener cada una de ellas
               </p>
               <Link to="/articulo/las-4-politicas-legalmente-obligatorias-que-ha-de-tener-tu-tienda-shopify" className="btn btn-light rounded text-dark px-4">
                  Leer Más
               </Link>
            </div>
         </Link>
      </div>
   );
}

export default Blog;
