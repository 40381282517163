import React from 'react';
import MailerFind from '../assets/img/mailerfind.png';

function MailerFindAd() {
   return (
      <a className="card rounded border-0 text-decoration-none mb-4" target="_blank" rel="noreferrer" href="https://mailerfind.com/es?source=generadordepoliticas.com">
         <img src={MailerFind} className="img-fluid rounded-top p-5" alt="MailerFind" />
         <div className="card-body">
            <h3 className="m-0 d-inline-block mr-2 mb-1 h5">
               <b>MailerFind - Genera ventas sin gastar dinero en publicidad</b>
            </h3>
            <div className="row">
               <div className="col-12">
                  <p className="m-0 text-muted">
                  Ej: Si tu nicho es el de "cocina", con esta herramienta puedes conseguir los datos de contacto de los seguidores de cuentas de Instagram que compartan contenido sobre esta temática o incluso de tiendas competidoras. Impacta diariamente de forma masiva y automatizada a clientes potenciales interesados en lo que vendes.
                  </p>
               </div>
               <div className="col-12">
                  <a
                     className="button--primary btn btn-small float-right align-middle btn-block mt-3"
                     target="_blank"
                     rel="noreferrer"
                     href="https://mailerfind.com/es?source=generadordepoliticas.com"
                  >
                     SABER MÁS
                  </a>
               </div>
            </div>
         </div>
      </a>
   );
}

export default MailerFindAd;
