import React from 'react';
import { Helmet } from 'react-helmet';

function Metatags({ title, description, image }) {
   return (
      <Helmet>
         <title>{title}</title>
         <meta name="twitter:card" content="summary" />
         <meta name="twitter:site" content="@generarpoliticas" />
         <meta name="twitter:title" content={title} />
         <meta name="twitter:description" content={description} />
         <meta name="twitter:image" content={image} />

         <meta property="og:title" content={title} />
         <meta property="og:description" content={description} />
         <meta property="og:image" content={image} />
      </Helmet>
   );
}

export default Metatags;
