import React from 'react';
import VideoThumbnail from '../assets/img/dropshipping-video.jpeg';
import WinningProduct from '../assets/img/winning-product.jpg';
import Traffic from '../assets/img/120-pack-min.jpg';

function TrafficAds() {
   return (
      <>
         <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
               <div className="carousel-item">
                  <FacebookVideoAd />
               </div>
               <div className="carousel-item">
                  <FindProductAd />
               </div>
               <div className="carousel-item active">
                  <TrafficAd />
               </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
               <span className="carousel-control-prev-icon" aria-hidden="true" />
               <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
               <span className="carousel-control-next-icon" aria-hidden="true" />
               <span className="sr-only">Next</span>
            </a>
         </div>
      </>
   );
}

function Card({ title, description, link, image }) {
   return (
      <>
         <a className="card rounded border-0 text-decoration-none mb-4 mx-3" target="_blank" rel="noreferrer" href={link}>
            <img src={image} className="img-fluid rounded-top" alt={description} style={{ width: '100%', height: '250px', objectFit: 'cover' }} />
            <div className="card-body">
               <h3 className="m-0 d-inline-block mr-2 mb-1 h5">
                  <b>{title}</b>
               </h3>
               <div className="row">
                  <div className="col-12">
                     <p className="m-0 text-muted">{description}</p>
                  </div>
                  <div className="col-12">
                     <a className="button--primary btn btn-small float-right align-middle btn-block mt-3" target="_blank" rel="noreferrer" href={link}>
                        COMPRAR
                     </a>
                  </div>
               </div>
            </div>
         </a>
      </>
   );
}

function FacebookVideoAd() {
   return (
      <>
         <Card
            title="Crearé tu anuncio para Facebook Ads"
            description="Las personas pararán de hacer scroll al ver tu vídeo, lo que hará que tengas más ventas."
            link="https://go.fiverr.com/visit/?bta=314855&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fpromarketer101%2Fcreate-viral-dropshipping-facebook-video-ads-and-product-video-ads"
            image={VideoThumbnail}
         />
      </>
   );
}

function FindProductAd() {
   return (
      <>
         <Card
            title="Encuentro tu Producto Ganador"
            description="Te daré un producto de alta demanda y baja competencia para tu negocio de Dropshipping."
            link="https://go.fiverr.com/visit/?bta=314855&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Ffelixnguyen%2Ffind-your-winning-shopify-dropshipping-product"
            image={WinningProduct}
         />
      </>
   );
}

function TrafficAd() {
   return (
      <>
         <Card
            title="Envío 120K Visitantes a tu Tienda"
            description="Enviaré 120 mil visitantes a tu tienda Shopify para que generes ventas."
            link="https://go.fiverr.com/visit/?bta=314855&brand=fiverrcpa&landingPage=https%3A%2F%2Fwww.fiverr.com%2Fniftyvisitors%2Fsend-safe-organic-traffic-for-your-website"
            image={Traffic}
         />
      </>
   );
}

export default TrafficAds;
