import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/svg/new_logo.svg';

function SimpleNavbar() {
   return (
      <header>
         <nav className="navbar navbar-expand-lg navbar-light bg-white">
            <div className="container">
               <Link className="navbar-brand text-primary" to="/">
                  <img src={logo} alt="logo" className="img-fluid" style={{ maxWidth: '200px' }} />
               </Link>
               <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
               >
                  <span className="navbar-toggler-icon" />
               </button>
               <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav ml-auto">
                     <li className="nav-item">
                        <Link className="btn button--primary" to="/">
                           Generar políticas
                        </Link>
                     </li>
                  </ul>
               </div>
            </div>
         </nav>
      </header>
   );
}

export default SimpleNavbar;
