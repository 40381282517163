import React, { useEffect } from 'react';
import PolicyTemplate from '../components/PolicyTemplate';

function PrivacyPolicy() {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <PolicyTemplate
         metaTitle="🥇 Generar Politica de Privacidad para Shopify Gratis en Español 2023"
         metaDescription="Genera la política de privacidad para tu tienda de Shopify accediendo ahora a este generador."
         title="Genera la Política de Privacidad para tu tienda Shopify"
         description="A partir del formulario que tienes a la derecha, podrás generar una plantilla personalizada de privacidad para tu tienda de Shopify."
         textareaTitle="Política de Privacidad"
         textareaDescription="La política de privacidad asegura a los clientes que compran a través de tu tienda que los datos personales que están dejando serán recopilados para un fin específico que conocerán y que lo harás siempre bajo su consentiemiento, protegiendo su privacidad."
         textareaType={1}
      />
   );
}

export default PrivacyPolicy;
