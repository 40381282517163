import React, { useEffect, useState } from 'react';
import PolicyTextarea from '../components/PolicyTextarea';
import Reviews from '../components/Reviews';
import Header from '../sections/PoliciesSections/Header';
import jump from 'jump.js';
import Metatags from '../components/Metatags';
import { Link } from 'react-router-dom';

function PolicyTemplate({ metaTitle, metaDescription, title, description, textareaTitle, textareaDescription, textareaType }) {
   const [data, setData] = useState(null);
   const sendData = (data) => {
      setData(data);
   };

   const goToFormSection = () => {
      // Scroll down to textarea section
      jump('#textareaSection', {
         duration: 1000,
         offset: -100,
         a11y: false,
      });
   };

   useEffect(() => {
      // Scroll down to textarea section
      if (data !== null) goToFormSection();
   }, [data]);
   return (
      <main>
         <Metatags title={metaTitle} description={metaDescription} />
         <Header sendData={sendData} title={title} description={description} />
         <div className="my-5">
            <Reviews
               reviews={[
                  {
                     name: 'Laura Cantero',
                     review: 'Es genial! Una vez has generado las políticas, puedes insertar las plantillas rellenadas en tu tienda utilizando el editor visual.',
                  },
                  { name: 'Rafa Herrera', review: 'Aquí podrás generar las políticas de privacidad, reembolso, envios y aviso legal de tu tienda Shopify.' },
                  { name: 'Berta Izquierdo', review: 'Este generador es ideal si tienes una tienda en Shopify pues tendrás la regulación al dia.' },
               ]}
            />
         </div>

         <section className="container my-5" id="textareaSection">
            <PolicyTextarea title={textareaTitle} description={textareaDescription} type={textareaType} data={data} />
         </section>

         <section className="container my-5">
            <h3 className="mb-4">
               <b>¿Necesitas Generar más Políticas?</b>
            </h3>
            <div className="row">
               {textareaType !== 0 && (
                  <div className="col-lg-4">
                     <PolicyShortcut title={'Política de Reembolso'} description={'Para Shopify'} href={'politica-de-reembolso'} />
                  </div>
               )}
               {textareaType !== 1 && (
                  <div className="col-lg-4">
                     <PolicyShortcut title={'Politica de Privacidad'} description={'Para Shopify'} href={'politica-de-privacidad'} />
                  </div>
               )}
               {textareaType !== 2 && (
                  <div className="col-lg-4">
                     <PolicyShortcut title={'Términos del Servicio'} description={'Para Shopify'} href={'terminos-del-servicio'} />
                  </div>
               )}
               {textareaType !== 3 && (
                  <div className="col-lg-4">
                     <PolicyShortcut title={'Política de Envío'} description={'Para Shopify'} href={'politica-de-envio'} />
                  </div>
               )}
            </div>
            <div className="my-4 text-center">
               <Link to="/">
                  <span>Generar todas {'>'}</span>
               </Link>
            </div>
         </section>
      </main>
   );
}

function PolicyShortcut({ title, description, href }) {
   return (
      <Link to={href}>
         <div className="card rounded">
            <div className="card-body d-flex">
               <div className="my-auto mr-3">
                  <ion-icon name="document-text" size="large"></ion-icon>
               </div>
               <div>
                  <h5 className="mb-0">{title}</h5>
                  <p className="text-muted mb-0">{description}</p>
               </div>
            </div>
         </div>
      </Link>
   );
}

export default PolicyTemplate;
