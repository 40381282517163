import React, { useEffect } from 'react';
import PolicyTemplate from '../components/PolicyTemplate';

function TermsOfService() {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <PolicyTemplate
         metaTitle="🥇 Generar Los Términos del Servicio para Shopify Gratis en Español 2023"
         metaDescription="Genera los términos del servicio para tu tienda de Shopify accediendo ahora a este generador."
         title="Genera los Términos del Servicio para tu tienda Shopify"
         description="A partir del formulario que tienes a la derecha, podrás generar una plantilla de términos de servicio para tu tienda de Shopify."
         textareaTitle="Términos del Servicio"
         textareaDescription="Los términos y condiciones son un conjunto de términos legales definidos por el propietario de una página web. Los Términos son un acuerdo entre el propietario del sitio web y los usuarios de la página web; detallan las políticas y procedimientos realizados por el sitio web."
         textareaType={2}
      />
   );
}

export default TermsOfService;
