import React, { useState, useEffect } from 'react';
import BusinessForm from '../../components/BusinessForm';

function Header(props) {
   const [data, setData] = useState(null);
   const sendData = (data) => {
      setData(data);
   };

   useEffect(() => {
      // This means form has been submited
      props.sendData(data);
   }, [data]);

   return (
      <section className="pt-5">
         <div className="container mt-5">
            <div className="row">
               <div className="col-lg-5 mt-5">
                  <h1 className="mb-4">
                     <b>
                        {props.title}{' '}
                        <span role="img" aria-label="medal">
                           🥇
                        </span>
                     </b>
                  </h1>
                  <h2 className="lead text-muted mb-4">{props.description}</h2>
                  <button className="button--primary btn mr-3">Rellenar el formulario</button>
                  <a className="text-primary ml-3" href="/">
                     Saber más {'>'}
                  </a>
               </div>
               <div className="col-lg-7">
                  <BusinessForm sendData={sendData} />
               </div>
            </div>
         </div>
      </section>
   );
}

export default Header;
