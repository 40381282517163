import React from 'react';

function Reviews({ reviews }) {
   return (
      <div className="container">
         <div className="row">
            <div className="col-lg-4">
               <div className="card rounded mt-4">
                  <div className="card-body">
                     <h4>
                        <b>{reviews[0].name}</b>
                     </h4>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <p className="lead mb-0">{reviews[0].review}</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-4">
               <div className="card rounded mt-4">
                  <div className="card-body">
                     <h4>
                        <b>{reviews[1].name}</b>
                     </h4>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <p className="lead mb-0">{reviews[1].review}</p>
                  </div>
               </div>
            </div>
            <div className="col-lg-4">
               <div className="card rounded mt-4 mb-4">
                  <div className="card-body">
                     <h4>
                        <b>{reviews[2].name}</b>
                     </h4>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <ion-icon name="star" color="warning"></ion-icon>
                     <p className="lead mb-0">{reviews[2].review}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Reviews;
