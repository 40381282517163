import React, { useEffect } from 'react';
import PolicyTemplate from '../components/PolicyTemplate';

function RefundPolicy(props) {
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <PolicyTemplate
         metaTitle="🥇 Generar Politica de Reembolso para Shopify Gratis en Español 2023"
         metaDescription="Genera la política de reembolso para tu tienda de Shopify accediendo ahora a este generador."
         title="Genera la Política de Reembolso para tu tienda Shopify"
         description="A partir del formulario que tienes a la derecha, podrás generar una plantilla personalizada de reembolso para tu tienda de Shopify."
         textareaTitle="Política de Reembolso (o devoluciones)"
         textareaDescription="La política de reembolso, o también conocida como política de devolución, informa a tus clientes sobre cómo tu negocio gestiona los reembolsos o devoluciones de los productos que vendes. Una buena política de reembolso o devolución puede proteger a tu empresa y ayudarte a ganar la confianza de tus clientes."
         textareaType={0}
      />
   );
}

export default RefundPolicy;
