import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
   const currentYear = new Date().getFullYear();
   return (
      <footer className="pt-5">
         <nav className="bg-light p-5">
            <div className="container mb-4 py-5">
               <div className="row">
                  <div className="col-lg-4">
                     <p className="text-primary">
                        <b>Generador de Políticas</b>
                     </p>
                     <p className="text-muted">
                        www.generadordepoliticas.com ©<br /> {currentYear} Todos los Derechos Reservados
                     </p>
                  </div>
                  <ul className="col-lg-4 list-unstyled">
                     <li className="text-primary mb-4">
                        <b>Páginas</b>
                     </li>
                     <li>
                        <Link to="/" className="text-muted">
                           Inicio
                        </Link>
                     </li>
                     <li>
                        <Link to="/politica-de-reembolso" className="text-muted">
                           Generar Política de Reembolso
                        </Link>
                     </li>
                     <li>
                        <Link to="/politica-de-privacidad" className="text-muted">
                           Generar Política de Privacidad
                        </Link>
                     </li>
                     <li>
                        <Link to="/terminos-del-servicio" className="text-muted">
                           Generar Términos del Servicio
                        </Link>
                     </li>
                     <li>
                        <Link to="/politica-de-envio" className="text-muted">
                           Generar Política de Envío
                        </Link>
                     </li>
                  </ul>
                  <ul className="col-lg-4 list-unstyled">
                     <li className="text-primary mb-4">
                        <b>Legal</b>
                     </li>
                     <li>
                        <Link to="/legal/politica-de-cookies" className="text-muted">
                           Política de Cookies
                        </Link>
                     </li>
                     <li>
                        <Link to="/legal" className="text-muted">
                           Aviso Legal
                        </Link>
                     </li>
                  </ul>
               </div>
            </div>

            <div className="d-flex mx-auto mb-5">
               <small className="mx-auto text-muted text-center" style={{ maxWidth: '800px' }}>
                  Esta web no pertenece a Shopify o Shopify Inc. y tampoco está respaldada por Shopify de ninguna manera. Shopify proporciona una plataforma para ofrecer creación y
                  construcción de tiendas en línea, pero los contenidos ofrecidos en esta web no son representativos de Shopify Inc.
               </small>
            </div>

            <p className="text-center text-secondary mb-0">
               Desarrollado por{' '}
               <a href="https://peterpalmer.io" target="_blank" rel="noreferrer">
                  Peter Palmer
               </a>
               .
            </p>
         </nav>
      </footer>
   );
}

export default Footer;
