import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

function CookiePolicy() {
   // Scroll to top when render
   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);
   return (
      <>
         <Helmet>
            <title>Política de Cookies</title>
            <meta name="description" content="Política de Cookies Generador Políticas Shopify" />
         </Helmet>
         <div className="container py-4 text-justify">
            <h1 className="text-center">Política de Cookies</h1>
            <p className="p1">
               <strong>¿Qué son las cookies?</strong>
            </p>
            <p className="p1">
               Las cookies son pequeños archivos que algunas plataformas, como las páginas web, pueden instalar en su ordenador, smartphone, tableta o televisión conectada, al
               acceder a las mismas. Sus funciones pueden ser muy variadas: almacenar sus preferencias de navegación, recopilar información estadística, permitir ciertas
               funcionalidades técnicas, almacenar y recuperar información sobre los hábitos de navegación o preferencias de un usuario o de su equipo hasta el punto, en ocasiones,
               dependiendo de la información que contengan y de la forma en que utilice su equipo, de poder reconocerlo. Una cookie se almacena en un ordenador para personalizar y
               facilitar al máximo la navegación del usuario. Las cookies se asocian únicamente a un usuario y su ordenador y no proporcionan referencias que permitan deducir datos
               personales del usuario. El usuario podrá configurar su navegador para que notifique o rechace la instalación de las cookies enviadas por el sitio web.
            </p>
            <p className="p1">
               <strong>¿Por qué son importantes?</strong>
            </p>
            <p className="p1">
               Las cookies son útiles por varios motivos. Desde un punto de vista técnico, permiten que las páginas web funcionen de forma más ágil y adaptada a las preferencias
               del usuario como, por ejemplo, almacenar su idioma o la moneda de su país. Además, ayudan a los responsables de los sitios web a mejorar los servicios que ofrecen,
               gracias a la información estadística que recogen a través de ellas. Finalmente, sirven para hacer más eficiente la publicidad que le mostramos, gracias a la cual le
               podemos ofrecer servicios de forma gratuita.
            </p>
            <p className="p1">
               Las Cookies, en función de su permanencia, pueden dividirse en Cookies de sesión o permanentes. Las primeras expiran cuando el usuario cierra el navegador. Las
               segundas expiran en función de cuando se cumpla el objetivo para el que sirven o bien cuando se borran manualmente.
            </p>
            <p className="p1">
               <strong>¿Cómo utilizamos las cookies?</strong>
            </p>
            <p className="p1">Navegar por este portal supone que se puedan instalar los siguientes tipos de cookies:</p>
            <hr />
            <h6 className="p1">
               <span className="s1">Cookies de sesión</span>
            </h6>
            <p className="p1">Están diseñadas para recabar y almacenar datos mientras el usuario accede a una página web.</p>
            <hr />
            <h6 className="p1">
               <span className="s1">Cookies de mejora del rendimiento</span>
            </h6>
            <p className="p1">
               Este tipo de cookies conserva sus preferencias para ciertas herramientas o servicios para que no tenga que reconfigurarlos cada vez que visita nuestro portal y, en
               algunos casos, pueden ser aportadas por terceros. Algunos ejemplos de este tipo de cookies son: ajuste del volumen de los reproductores audiovisuales, preferencias
               de ordenación de artículos o velocidades de reproducción de vídeo compatibles. En el caso de comercio electrónico, permiten mantener información sobre su cesta de la
               compra.
            </p>
            <hr />
            <h6 className="p1">
               <span className="s1">Cookies de análisis estadístico</span>
            </h6>
            <p className="p1">
               Son aquellas que, bien tratadas por nosotros o por terceros, permiten cuantificar el número de visitantes y analizar estadísticamente la utilización que hacen los
               usuarios de nuestros servicios. Gracias a ellas podemos estudiar la navegación por nuestra página web y mejorar así la oferta de productos o servicios que ofrecemos.
               Estas cookies no irán asociadas a ningún dato de carácter personal que pueda identificar al usuario, dando información sobre el comportamiento de navegación de forma
               anónima.
            </p>
            <hr />
            <h6 className="p1">
               <span className="s1">Cookies de geolocalización</span>
            </h6>
            <p className="p1">
               Estas cookies son usadas por programas que intentan localizar geográficamente la situación del ordenador, smartphone, tableta o televisión conectada, para de manera
               totalmente anónima ofrecerle contenidos y servicios más adecuados.
            </p>
            <hr />
            <h6 className="p1">
               <span className="s1">Cookies de registro</span>
            </h6>
            <p className="p1">
               Cuando usted se registra en nuestro portal, se generan cookies que le identifican como usuario registrado e indican cuándo usted se ha identificado en el portal.
               Estas cookies son utilizadas para identificar su cuenta de usuario y sus servicios asociados, facilitando así su navegación. Estas cookies se mantienen mientras
               usted no abandone la cuenta, cierre el navegador o apague el dispositivo. Estas cookies pueden ser utilizadas en combinación con datos analíticos para identificar de
               manera individual sus preferencias en nuestro portal.
            </p>
            <hr />
            <h6 className="p1">
               <span className="s1">Cookies publicitarias</span>
            </h6>
            <p className="p1">
               Son aquéllas que, bien tratadas por nosotros o por terceros, permiten gestionar eficazmente los espacios publicitarios de nuestro sitio web, adecuando el contenido
               del anuncio al contenido del servicio solicitado o al uso que realice de nuestra página web. Gracias a ella podemos conocer sus hábitos de navegación en internet y
               mostrarle publicidad relacionada con su perfil de navegación.
            </p>
            <hr />
            <h6 className="p1">
               <span className="s1">Otras cookies de terceros</span>
            </h6>
            <p className="p1">
               En algunas de nuestras páginas se pueden instalar cookies de terceros que permitan gestionar y mejorar los servicios que éstos ofrecen. Un ejemplo de este uso son
               los enlaces a las redes sociales que permiten compartir nuestros contenidos.
            </p>
            <hr />
            <p className="p1">
               Nuestra empresa utiliza <strong>Google Analytics,</strong> un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal
               está en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos ("Google"). Google Analytics utiliza "Cookies", que son archivos de texto
               ubicados en su ordenador, para ayudar al website a analizar el uso que hacen los usuarios del sitio web. La información que genera las Cookies acerca de su uso del
               website (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usará esta información por
               cuenta nuestra con el propósito de seguir la pista de su uso del website, recopilando informes de la actividad del website y prestando otros servicios relacionados
               con la actividad del website y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos
               terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga Google. Puede Usted rechazar el
               tratamiento de los datos o la información rechazando el uso de Cookies mediante la selección de la COOKIES configuración apropiada de su navegador. Para conocer más
               información hacer clic sobre el tipo de cookies que utiliza google. <span className="s2">http://www.google.com/policies/technologies/types/</span>
            </p>
            <br />
            <table cellPadding={0} cellSpacing={0} className="t1">
               <tbody>
                  <tr>
                     <td className="td1" colSpan={3} valign="middle">
                        <h4 className="p4">Cookies propias o estrictamente necesarias</h4>
                     </td>
                  </tr>
                  <tr>
                     <td className="td1" valign="middle">
                        <p className="p5">Cookie</p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p6">
                           <strong>Información&nbsp;</strong>(*)
                        </p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p6">
                           <strong>Finalidad</strong>
                        </p>
                     </td>
                  </tr>
                  <tr>
                     <td className="td1" valign="middle">
                        <p className="p7">cookieBannerHasBeenAccepted</p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p6">Comprueba si el banner de las cookies ha sido aceptado</p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p6">No volver a mostrar el banner is éste ha sido aceptado previamente.</p>
                     </td>
                  </tr>
               </tbody>
            </table>
            <p className="p8">
               <br />
            </p>
            <p className="p8">
               <br />
            </p>
            <table cellPadding={0} cellSpacing={0} className="t1">
               <tbody>
                  <tr>
                     <td className="td1" colSpan={5} valign="middle">
                        <h4 className="p6">Cookies de terceros</h4>
                     </td>
                  </tr>
                  <tr>
                     <td className="td1" valign="middle">
                        <p className="p5">Cookie</p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p5">
                           <strong>Información</strong>(*)
                        </p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p5">
                           <strong>Finalidad</strong>
                        </p>
                     </td>
                     <td className="td1" colSpan={2} valign="middle">
                        <p className="p9">
                           <strong>Opt-Out</strong>
                        </p>
                     </td>
                  </tr>

                  <tr>
                     <td className="td1" valign="middle">
                        <p>Analíticas</p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p6">Google Analytics</p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p6">
                           Estadísticas de número de visitas a páginas, tiempos de navegación, tipos de navegador, estadísticas de navegación, redirecciones, motores de búsqueda,
                           sites referidos, etc.
                        </p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p6">
                           Recopilar y unificar en informes estadísticos sobre el tráfico de cada sitio, audiencia total, visitas a páginas concretas, seguimiento de clicks sobre
                           determinadas partes del sitio, etc.
                        </p>
                     </td>
                     <td className="td1" valign="middle">
                        <p className="p10">
                           <span className="s1">https://tools.google.com/dlpage/gaoptout</span>
                        </p>
                     </td>
                  </tr>
               </tbody>
            </table>
            <br />
            <p className="p1">
               <strong>¿Cómo puedo configurar mis preferencias?</strong>
            </p>
            <p className="p1">
               Usted puede configurar sus preferencias de uso de las Cookies de nuestra página en cualquier momento accediendo a la página de{' '}
               <span className="s3">configuración de Cookies</span>.
            </p>
            <p className="p1">
               Usted puede permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones de su navegador de internet.
            </p>
            <p className="p1">A continuación, le ofrecemos enlaces en los que encontrará información sobre cómo puede activar sus preferencias en los principales navegadores:</p>
            <p className="p14">El usuario puede en cualquier momento revocar el funcionamiento de las cookies de ese sitio web mediante:</p>
            <ul className="ul1">
               <li className="li14">La configuración del navegador:</li>
            </ul>
            <p className="p15">
               <span className="s4">Chrome, desde&nbsp;</span>
               <span className="s1">http://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647</span>
            </p>
            <p className="p15">
               <span className="s4">Explorer, desde&nbsp;</span>
               <span className="s1">http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9</span>
            </p>
            <p className="p15">
               <span className="s4">Firefox, desde&nbsp;</span>
               <span className="s1">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we</span>
            </p>
            <p className="p16">
               <span className="s4">Safari, desde &nbsp;</span>http://support.apple.com/kb/ph5042
            </p>
            <ul className="ul1">
               <li className="li14">
                  Los sistemas de opt-aut indicados en la tabla respecto a cada una de las cookies donde aplica, pueden conllevar que se instale en su equipo una cookie de rechazo
                  para que funcione su elección de desactivación.
               </li>
            </ul>
            <p className="p1">
               Finalmente, puede usted dirigirse al portal Your Online Choices dónde además de encontrar información útil, podrá configurar, proveedor por proveedor, sus
               preferencias sobre las cookies publicitarias de terceros.
            </p>
            <p className="p1">
               <strong>¿Qué ocurre si se deshabilitan las cookies?</strong>
            </p>
            <p className="p1">
               En el caso de bloquear o no aceptar la instalación de cookies es posible que ciertos servicios ofrecidos por nuestro sitio web que necesitan su uso queden
               deshabilitados y, por lo tanto, no estén disponibles para usted por lo que no podrá aprovechar por completo todo lo que nuestras webs y aplicaciones le ofrecen. Es
               posible también que la calidad de funcionamiento de la página web pueda disminuir.
            </p>
            <p className="p1">
               <strong>Aceptación de cookies</strong>
            </p>
            <p className="p1">Si usted sigue navegando después de haberle informado sobre nuestra Política de cookies entendemos que acepta la utilización de las cookies.</p>
            <p className="p1">
               Al acceder a este sitio web o aplicación por primera vez, verá una ventana donde se le informa de la utilización de las cookies y donde puede consultar esta política
               de cookies. Si usted consiente la utilización de cookies, continúa navegando o hace clic en algún link se entenderá que usted ha consentido nuestra política de
               cookies y, por tanto, la instalación de las mismas en su equipo o dispositivo.
            </p>
            <p className="p1">
               Además del uso de nuestras cookies propias, permitimos a terceros establecer cookies y acceder a ellas en su ordenador. El consentimiento del uso de las cookies de
               estas empresas está ligado a la navegación por este sitio web.
            </p>
            <p className="p1">
               <strong>Actualizaciones en la Política de Cookies</strong>
            </p>
            <p className="p1">
               Nuestro portal puede modificar esta Política de Cookies en función de exigencias legislativas, reglamentarias, o con la finalidad de adaptar dicha política a las
               instrucciones dictadas por la Agencia Española de Protección de Datos, por lo que se aconseja a los usuarios que la visiten periódicamente.
            </p>
            <p className="p17">
               <br />
            </p>
            <p className="p17">
               <strong>Página de Configuración de Cookies.</strong>
            </p>
            <p>
               <em>Todas las cookies son estrictamente necesarias.</em>
            </p>
            <p className="p2">
               <br />
            </p>
            <p className="p19">
               <strong>Gestión y configuración de cookies</strong>
            </p>
            <p className="p20">
               <strong>Su privacidad es importante para nosotros</strong>
            </p>
            <p className="p21">
               Utilizamos cookies propias y de terceros para personalizar la web y analizar nuestros servicios basándonos en tus hábitos de navegación. Puedes obtener más
               información pinchando en el enlace política de privacidad justo aquí abajo.
            </p>
         </div>
      </>
   );
}

export default CookiePolicy;
