import React, { useState, useEffect, useRef } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { policies } from '../assets/json/policies3.json';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import QuillBetterTable from 'quill-better-table';

function PolicyTextarea(props) {
   const [policiesToShow, setPoliciesToShow] = useState('');
   const [copyButtonClassName, setCopyButtonClassName] = useState('');
   const { data } = props;

   const quillEditor = useRef(null);

   Quill.register(
      {
         'modules/better-table': QuillBetterTable,
      },
      true
   );

   useEffect(() => {
      if (!data) return;

      function replaceValues(string) {
         string = string
            .replace(/{{EMAIL}}/g, data.email)
            .replace(/{{ADDRESS}}/g, data.address)
            .replace(/{{APARTMENT}}/g, data.apartment)
            .replace(/{{POSTAL_CODE}}/g, data.postalCode)
            .replace(/{{CITY}}/g, data.city)
            .replace(/{{WEBSITE}}/g, data.website)
            .replace(/{{PROVINCE}}/g, data.province)
            .replace(/{{BUSINESS_NAME}}/g, data.businessName)
            .replace(/{{COUNTRY}}/g, data.country);
         return string;
      }

      if (!data.email) {
         setPoliciesToShow('');
         return;
      }

      switch (props.type) {
         case 0:
            const politicaDeReembolso = replaceValues(data.language === 'spanish' ? policies.spanish[0].content : policies.english[0].content);
            setPoliciesToShow(politicaDeReembolso);
            break;

         case 1:
            const politicaDePrivacidad = replaceValues(data.language === 'spanish' ? policies.spanish[1].content : policies.english[1].content);
            setPoliciesToShow(politicaDePrivacidad);
            break;

         case 2:
            const terminosDelServicio = replaceValues(data.language === 'spanish' ? policies.spanish[2].content : policies.english[2].content);
            setPoliciesToShow(terminosDelServicio);
            break;

         case 3:
            const politicaDeEnvio = replaceValues(data.language === 'spanish' ? policies.spanish[3].content : policies.english[3].content);
            setPoliciesToShow(politicaDeEnvio);
            break;

         case 4:
            const politicaDeCookies = replaceValues(data.language === 'spanish' ? policies.spanish[4].content : 'Comming Soon!');
            setPoliciesToShow(politicaDeCookies);
            break;

         default:
            setPoliciesToShow('');
            break;
      }
   }, [data]);

   const handleClick = () => {
      toast('⚡ Política Copiada ');
      setCopyButtonClassName('jello');
      copyToClipboard();

      // Reset Copy Button ClassName
      setTimeout(function () {
         setCopyButtonClassName('');
      }, 700);
   };

   const handleChange = (value) => {
      setPoliciesToShow(value);
   };

   const copyToClipboard = () => {
      const el = document.createElement('textarea');
      el.value = policiesToShow;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
   };

   return (
      <div className="card rounded border-0 shadow px-4 py-3 mb-5">
         <div className="form-group">
            <h2 className="h4">
               <b>{props.title}</b>
            </h2>
            <p className="text-muted mb-4 text-justify">{props.description}</p>
            <ReactQuill ref={quillEditor} style={{ height: '500px' }} theme="snow" value={policiesToShow} onChange={handleChange} />
            <button className={`button--primary btn float-right mt-4 ${copyButtonClassName}`} onClick={handleClick}>
               <ion-icon name="clipboard" className="my-auto"></ion-icon> Copiar
            </button>
         </div>
      </div>
   );
}

export default PolicyTextarea;
