import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { policies } from '../../assets/json/policies3.json';
import verifyEmailExists from '../../lib/emailVerify';
import axios from 'axios';

function EmailModal(props) {
   const [loading, setLoading] = useState(false);
   const handleClose = () => props.setShowModal(false);
   const handleShow = () => props.setShowModal(true);
   const [formData, setFormData] = useState(JSON.parse(localStorage.getItem('formData')));
   const [validInputs, setValidInputs] = useState({});
   const [data, setData] = useState({
      firstName: '',
      email: '',
   });

   useEffect(() => {
      setFormData(JSON.parse(localStorage.getItem('formData')));
   }, [props.showModal]);

   useEffect(() => {
      if (!formData || !formData.email) return;
      //setData({ ...data, email: formData.email });
   }, [formData]);

   const handleChange = (e) => {
      setData({ ...data, [e.target.name]: e.target.value });
   };

   const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

   const handleSubmit = async (e) => {
      e.preventDefault();

      setLoading(true);
      localStorage.setItem('hasLeftEmail', true);

      // Update localStorage
      formData.firstName = data.firstName;
      localStorage.setItem('formData', JSON.stringify(formData));

      const politicaDeReembolso = replaceValues(formData.language === 'spanish' ? policies.spanish[0].content : policies.english[0].content);
      const politicaDePrivacidad = replaceValues(formData.language === 'spanish' ? policies.spanish[1].content : policies.english[1].content);
      const terminosDelServicio = replaceValues(formData.language === 'spanish' ? policies.spanish[2].content : policies.english[2].content);
      const politicaDeEnvio = replaceValues(formData.language === 'spanish' ? policies.spanish[3].content : policies.english[3].content);

      // Await is disabled. Then the user has not to wait until this is done and can access to the policies!

      // If they input the surname, automatically split it and save it.
      const fullName = data.firstName.split(' ');
      data.firstName = fullName[0];
      data.lastName = fullName[1];

      // comprobar que el email introducido es real y existe
      const validation = await verifyEmailExists(data.email);
      const { valid } = validation;
      if (!valid) {
         setValidInputs({ ...validInputs, email: false, emailMessage: 'El email introducido no es válido.' });
         setLoading(false);
         return;
      } else {
         setValidInputs({});
      }

      // Send email with the policies
      axios
         .post('https://us-central1-generar-politicas-shopify.cloudfunctions.net/widgets/send-email', {
            nombre: capitalize(data.firstName),
            email: data.email,
            politicaDeReembolso,
            politicaDePrivacidad,
            terminosDelServicio,
            politicaDeEnvio,
         })
         .then(function (response) {
            console.log(response);
         })
         .catch(function (error) {
            console.log(error);
         });

      axios
         .post('https://us-central1-generar-politicas-shopify.cloudfunctions.net/widgets/create-new-contact', {
            name: capitalize(data.firstName),
            surname: data.lastName ? capitalize(data.lastName) : '',
            email: data.email,
            city: capitalize(formData.city),
            country: capitalize(formData.country),
            postalCode: formData.postalCode,
            address: capitalize(`${formData.address}, ${formData.apartment}`),
            province: formData.province,
            website: formData.website,
         })
         .then(function (response) {
            console.log(response);
         })
         .catch(function (error) {
            console.log(error);
         });

      setLoading(false);
      handleClose();
      props.hasSubmitted();
   };

   const cancel = () => {
      handleClose();
      localStorage.setItem('hasLeftEmail', false);
      props.hasSubmitted();
   };

   const replaceValues = (string) => {
      string = string
         .replace(/{{EMAIL}}/g, formData.email)
         .replace(/{{ADDRESS}}/g, formData.address)
         .replace(/{{APARTMENT}}/g, formData.apartment)
         .replace(/{{POSTAL_CODE}}/g, formData.postalCode)
         .replace(/{{CITY}}/g, formData.city)
         .replace(/{{WEBSITE}}/g, formData.website)
         .replace(/{{PROVINCE}}/g, formData.province)
         .replace(/{{BUSINESS_NAME}}/g, formData.businessName)
         .replace(/{{COUNTRY}}/g, formData.country);
      return string;
   };

   return (
      <Modal show={props.showModal} onHide={cancel} className="mt-5">
         <form onSubmit={handleSubmit}>
            <Modal.Body className="">
               <div className="text-right text-muted">
                  <ion-icon name="close-outline" onClick={cancel} style={{ cursor: 'pointer' }} size="large"></ion-icon>
               </div>
               <div className="px-5 pb-4">
                  <span class="badge badge-danger mb-2">¡Nuevo!</span>
                  <h5 className="mb-4 font-weight-bolder">¡No pierdas tus políticas! Te las enviamos a tu correo electrónico.</h5>
                  <div className="form-group">
                     <label htmlFor="exampleFormControlInput1">Tu Nombre</label>
                     <input type="text" className="form-control" placeholder="Javier" name="firstName" value={data.firstName} onChange={handleChange} required />
                  </div>
                  <div className="form-group">
                     <label htmlFor="emailInput">Tu Mejor Email</label>
                     <input
                        type="email"
                        className={`form-control ${validInputs?.email === true && 'is-valid'} ${validInputs?.email === false && 'is-invalid'}`}
                        placeholder="javier@ejemplo.com"
                        name="email"
                        value={data.email}
                        onChange={handleChange}
                        id="emailInput"
                        required
                     />
                     <div class="invalid-feedback">{validInputs?.emailMessage}</div>
                  </div>
                  <div className="form-check mb-3">
                     <input className="form-check-input" type="checkbox" defaultValue id="defaultCheck1" required />
                     <label className="form-check-label" htmlFor="defaultCheck1">
                        Acepto recibir información comercial o comunicación a través del correo electrónico
                     </label>
                  </div>
                  <button className="button--primary btn btn-block" id="track_me" type="submit" disabled={loading}>
                     {loading ? (
                        <div class="spinner-border spinner-border-sm" role="status">
                           <span class="sr-only">Loading...</span>
                        </div>
                     ) : (
                        '¡Ir a las Políticas!'
                     )}
                  </button>
               </div>
            </Modal.Body>
         </form>
      </Modal>
   );
}

export default EmailModal;
